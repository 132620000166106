import React from 'react';

import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import { ButtonType } from 'components/Button/Button';
import { StudentLoanApplyOnYourOwnResult } from 'enums/StudentLoanForgivenessFlowResults';
import CardSequence from 'components/Common/CardSequence';
import { ReactComponent as DebtIcon } from 'images/illustrative-icon-debt.svg';
import { ReactComponent as PaycheckIcon } from 'images/illustrative-icon-paycheck.svg';
import { ReactComponent as GiftIcon } from 'images/illustrative-icon-gift.svg';
import { Step } from 'components/Common/CardSequence/CardSequence';
import { addMonths } from 'utils/dateUtils';
import { PlanName, PlanShortName } from 'api/StudentLoanApi';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';

import styles from './ApplyOnYourOwn.module.scss';

const ApplyOnYourOwn = ({ navigationInfo, handleNext }: FlowComponentType) => {
  const { recommendedPath, currentPath } = useSelector(getStudentLoanData);

  if (!recommendedPath || !currentPath) {
    return <></>;
  }

  const currentPlans = parsePlans(currentPath.plans);
  const recommendedPlans = parsePlans(recommendedPath.plans);

  const steps: Step[] = [];

  const shouldChangeIdrEnrollment =
    recommendedPlans.isIdr && arePaymentPlansDifferent(currentPlans.paymentPlans, recommendedPlans.paymentPlans);

  if (shouldChangeIdrEnrollment) {
    let loansToConsolidate = '';
    if (currentPlans.isIdr) {
      loansToConsolidate = 'Direct Loans Only';
    } else if (recommendedPlans.paymentPlans.length > 1) {
      loansToConsolidate = 'Some';
    } else {
      loansToConsolidate = 'All';
    }
    steps.push({
      title: 'Consolidate your loans',
      body: (
        <div className={styles.stepBody}>
          <p>Consolidation helps you enroll in Income Driven Repayment, lowering your monthly bill.</p>
          {currentPlans.isIdr && (
            <p>
              <i>
                Do not consolidate FFELP loans (Stafford Loans, PLUS Loans, and Consolidation Loans). If you do so, you
                won’t be able to enter back into income based repayment.
              </i>
            </p>
          )}
          <div className={styles.info}>
            <div className={styles.name}>Loans to Consolidate</div>
            <div className={styles.value}>{loansToConsolidate}</div>
          </div>
        </div>
      ),
      icon: <DebtIcon />,
    });
  }

  if (shouldChangeIdrEnrollment) {
    steps.push({
      title: 'Enroll in Income Driven Repayment (IDR)',
      body: (
        <div className={styles.stepBody}>
          <p>
            {recommendedPlans.isPslf
              ? 'This payment plan is needed to enroll in Public Service Loan Forgiveness (PSLF).'
              : 'This will base your monthly payment on a percentage of your income, reducing your monthly payment.'}
          </p>
          <p>
            <i>
              Note: The government may not be processing IDR applications right now, so you may need to file this
              request with your servicer and contact the DoE to request administrative forbearance until enrolled.
            </i>
          </p>
          <div className={styles.info}>
            <div className={styles.name}>Plan Name</div>
            <div className={styles.value}>
              {recommendedPlans.paymentPlans.length > 1 ? 'Multiple' : recommendedPlans.paymentPlans[0]}
            </div>
          </div>
          <div className={styles.info}>
            <div className={styles.name}>Monthly Payment Est.</div>
            <div className={styles.value}>{formatMonetaryAmount(recommendedPath.payments[0])}</div>
          </div>
        </div>
      ),
      icon: <PaycheckIcon />,
    });
  }

  if (recommendedPlans.isPslf && !currentPlans.isPslf) {
    const forgivenessDate = addMonths(new Date(), recommendedPath.payments.length);
    steps.push({
      title: 'Enroll in Public Service Loan Forgiveness',
      body: (
        <div className={styles.stepBody}>
          <p>
            Enroll as soon as possible. You need to make 120 qualifying payments while working at a not for profit
            organization to achieve forgiveness.
          </p>
          <div className={styles.info}>
            <div className={styles.name}>Estimated Date</div>
            <div className={styles.value}>
              {forgivenessDate.toLocaleDateString('en-US', { month: 'short', year: 'numeric' })}
            </div>
          </div>
        </div>
      ),

      icon: <GiftIcon />,
    });
  }

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <FormContainer title="Enroll on StudentAid.gov" subtitle="Follow these steps to maximize your savings.">
        <CardSequence
          steps={[
            {
              center: true,
              title: (
                <p>
                  Log in to{' '}
                  <a href="https://studentaid.gov/fsa-id/sign-in" target="_blank" rel="noreferrer">
                    studentaid.gov
                  </a>
                  .
                </p>
              ),
            },
            ...steps,
          ]}
        />

        <div className={styles.buttonContainer}>
          <Button onClick={() => handleNext(StudentLoanApplyOnYourOwnResult.Done)}>Done</Button>
          <Button type={ButtonType.Secondary} onClick={() => handleNext(StudentLoanApplyOnYourOwnResult.ApplyWithUs)}>
            Talk to an Advisor
          </Button>
        </div>
      </FormContainer>
    </>
  );
};

export const parsePlans = (plans: PlanName[]): { isPslf: boolean; isIdr: boolean; paymentPlans: PlanName[] } => {
  const isPslf = plans.includes('PSLF');
  const isIdr =
    plans.includes(PlanShortName.Paye) ||
    plans.includes(PlanShortName.Icr) ||
    plans.includes(PlanShortName.Ibr) ||
    plans.includes(PlanShortName.Save);
  const paymentPlans = plans.filter((plan) => plan !== 'PSLF');
  return { isPslf, isIdr, paymentPlans };
};

export const arePaymentPlansDifferent = (currentPlans: PlanName[], recommendedPlans: PlanName[]) => {
  const currentPaymentPlans = currentPlans.filter((plan) => plan !== 'PSLF');
  const recommendedPaymentPlans = recommendedPlans.filter((plan) => plan !== 'PSLF');
  if (currentPaymentPlans.length !== recommendedPaymentPlans.length) {
    return true;
  }
  return currentPaymentPlans.some((plan, index) => plan !== recommendedPaymentPlans[index]);
};

export default ApplyOnYourOwn;
