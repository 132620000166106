import React from 'react';
import { useSelector } from 'react-redux';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { StudentLoanSavingsResult } from 'enums/StudentLoanForgivenessFlowResults';
import { FlowComponentType } from 'routes/types';

import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import StudentLoanLoaderWrapper from 'components/StudentLoanForgiveness/LoaderWrapper';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as LockOpenIcon } from 'images/lock-open.svg';
import { ReactComponent as GrowthIcon } from 'images/icon-growth.svg';
import SavingsTimeChart from 'components/StudentLoanForgiveness/StudentLoanSavings/SavingsTimeChart';
import SavingsTable from 'components/StudentLoanForgiveness/StudentLoanSavings/SavingsTable';

import styles from './EstimateUpdated.module.scss';

const EstimateUpdated = ({ flags, navigationInfo, handleNext }: FlowComponentType) => {
  const { currentPath: currentPathData, recommendedPath: recommendedPathData } = useSelector(getStudentLoanData);

  const savings =
    currentPathData?.totalPayment !== undefined && recommendedPathData?.totalPayment !== undefined
      ? currentPathData?.totalPayment - recommendedPathData?.totalPayment
      : null;

  if (!savings) {
    return <></>;
  }

  return (
    <StudentLoanLoaderWrapper flags={flags}>
      <>
        <FormNavigation {...navigationInfo} />
        <FormContainer
          title="Your Estimate Has Changed!"
          subtitle={
            <>
              <p>Your estimated savings from federal programs:</p>
              <div className={styles.yearsToSaveLabel}>{formatMonetaryAmount(savings)}</div>
            </>
          }
          icon={<LockOpenIcon />}
        >
          <SavingsTable />

          <SavingsTimeChart>
            <div className={styles.label}>
              <GrowthIcon /> Compare over time
            </div>
          </SavingsTimeChart>

          <div className={styles.buttonContainer}>
            <Button className={styles.button} onClick={() => handleNext(StudentLoanSavingsResult.Next)}>
              Next
            </Button>
          </div>
        </FormContainer>
      </>
    </StudentLoanLoaderWrapper>
  );
};

export default EstimateUpdated;
