import { KnownEmployerProfitStatus } from 'api/StudentLoanApi';
import EmployerType from 'components/StudentLoanForgiveness/Questions/EmployerType';
import NonProfitStartDate from 'components/StudentLoanForgiveness/Questions/NonProfitStartDate';
import StudentLoanSavings from 'components/StudentLoanForgiveness/StudentLoanSavings/YourSavings/StudentLoanSavings';
import BookAdvisor from 'components/StudentLoanForgiveness/BookAdvisor/BookAdvisor';
import DependentsQuantity from 'components/StudentLoanForgiveness/Questions/DependentsQuantity';
import ScheduleDetails from 'components/StudentLoanForgiveness/ScheduleDetails/ScheduleDetails';
import PaymentSituation from 'components/StudentLoanForgiveness/Questions/PaymentSituation';
import ApplyOptions from 'components/StudentLoanForgiveness/Apply/ApplyOptions';
import ApplyStepsIntro from 'components/StudentLoanForgiveness/Apply/ApplyStepsIntro';
import ApplyOnYourOwn from 'components/StudentLoanForgiveness/Apply/ApplyOnYourOwn';
import { ApplyNavigation } from 'components/StudentLoanForgiveness/Apply/applySteps';
import PaymentResult from 'components/StudentLoanForgiveness/Apply/PaymentResult';
import Submit from 'components/StudentLoanForgiveness/Apply/Submit';
import ApplyStepsCompleted from 'components/StudentLoanForgiveness/Apply/ApplyStepsCompleted';
import ComeBackWhenDue from 'components/StudentLoanForgiveness/ComeBackWhenDue/ComeBackWhenDue';
import EstimateUpdated from 'components/StudentLoanForgiveness/StudentLoanSavings/EstimateUpdated';
import NoSavings from 'components/StudentLoanForgiveness/NoSavings';

import { RoutePath } from 'enums/Routes';
import {
  StudentLoanEmployerResult,
  StudentLoanBookAdvisorResult,
  StudentLoanApplyOptionsResult,
  StudentLoanApplyOnYourOwnResult,
  StudentLoanPayingResult,
  PaymentPageResult,
  ComeBackWhenDueResult,
  PaymentSituationResult,
  UploadLoanInformationResult,
} from 'enums/StudentLoanForgivenessFlowResults';
import { StepsResult } from 'enums/FlowNextResults';
import { StudentLoanDataState } from 'handlers/studentLoanData';
import ServiceAgreementDone from 'components/StudentLoanForgiveness/Apply/Steps/ServiceAgreement/ServiceAgreementDone';
import UploadLoanInformation from 'components/StudentLoanForgiveness/UploadLoanInformation';
import { RootState } from 'handlers';

import { NextFunction, RouterType } from './types';

const title = 'Student Loan Forgiveness';

export const getStudentLoanForgivenessRoutes = (next: NextFunction): RouterType => ({
  [RoutePath.StudentLoanEmployerType]: {
    navigationInfo: { showBackLink: false, title },
    component: EmployerType,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StudentLoanEmployerResult.NonProfit:
          navigate(RoutePath.StudentLoanNonProfitStartDate);
          break;
        case StudentLoanEmployerResult.ForProfit:
        case StudentLoanEmployerResult.NotSure:
          navigate(RoutePath.StudentLoanDependentsQuantity);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanNonProfitStartDate]: {
    navigationInfo: { showBackLink: true, title },
    component: NonProfitStartDate,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanDependentsQuantity);
    },
  },
  [RoutePath.StudentLoanDependentsQuantity]: {
    navigationInfo: { showBackLink: true, title },
    component: DependentsQuantity,
    handleNext: ({ navigate, state }) => () => {
      if (!state.studentLoanData.hasMonthlyPayments) {
        navigate(RoutePath.StudentLoanPaymentSituations);
      } else if (shouldShowStudentLoanSavings(state)) {
        navigate(RoutePath.StudentLoanSavings);
      } else {
        navigate(RoutePath.StudentLoanUploadAidData);
      }
    },
  },
  [RoutePath.StudentLoanPaymentSituations]: {
    navigationInfo: { showBackLink: true, title },
    component: PaymentSituation,
    handleNext: ({ navigate, state }) => (result) => {
      switch (result as PaymentSituationResult) {
        case PaymentSituationResult.ImAStudent:
          navigate(RoutePath.StudentLoanComeBackWhenDue);
          break;
        default:
          if (shouldShowStudentLoanSavings(state)) {
            navigate(RoutePath.StudentLoanSavings);
          } else {
            navigate(RoutePath.StudentLoanUploadAidData);
          }
      }
    },
  },
  [RoutePath.StudentLoanComeBackWhenDue]: {
    navigationInfo: { showBackLink: true, title },
    component: ComeBackWhenDue,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case ComeBackWhenDueResult.TalkToAnAdvisor:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        default:
          next(args)(undefined);
      }
    },
  },
  [RoutePath.StudentLoanSavings]: {
    navigationInfo: { showBackLink: true, title },
    component: StudentLoanSavings,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanUploadAidData);
    },
  },
  [RoutePath.StudentLoanUploadAidData]: {
    navigationInfo: { showBackLink: true, title },
    component: UploadLoanInformation,
    handleNext: (args) => (result) => {
      const { navigate, state } = args;
      if (result === UploadLoanInformationResult.NotNow) {
        next(args)(undefined);
      } else if (result === UploadLoanInformationResult.EstimateChanged && shouldShowStudentLoanSavings(state)) {
        navigate(RoutePath.StudentLoanEstimateUpdated);
      } else if (getStudentLoanSavings(state) <= 0) {
        navigate(RoutePath.StudentLoanNoSavings);
      } else {
        navigate(RoutePath.StudentLoanApplyOptions);
      }
    },
  },
  [RoutePath.StudentLoanEstimateUpdated]: {
    navigationInfo: { showBackLink: true, title },
    component: EstimateUpdated,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanApplyOptions);
    },
  },
  [RoutePath.StudentLoanNoSavings]: {
    navigationInfo: { showBackLink: true, title },
    component: NoSavings,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case ComeBackWhenDueResult.TalkToAnAdvisor:
          navigate(RoutePath.StudentLoanBookAdvisor);
          break;
        default:
          next(args)(undefined);
      }
    },
  },
  [RoutePath.StudentLoanBookAdvisor]: {
    navigationInfo: { showBackLink: true, title },
    component: BookAdvisor,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case StudentLoanBookAdvisorResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanBookAdvisorResult.NotNow:
          next(args)(undefined);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanScheduleDetails]: {
    navigationInfo: { showBackLink: false, title },
    component: ScheduleDetails,
    handleNext: next,
  },
  [RoutePath.StudentLoanApplyOptions]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyOptions,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case StudentLoanApplyOptionsResult.ApplyWithUs:
          navigate(RoutePath.StudentLoanApplyWithUs);
          break;
        case StudentLoanApplyOptionsResult.ApplyOnYourOwn:
          navigate(RoutePath.StudentLoanApplyOnYourOwn);
          break;
        case StudentLoanApplyOptionsResult.Schedule:
          navigate(RoutePath.StudentLoanScheduleDetails);
          break;
        case StudentLoanApplyOptionsResult.NotNow:
          next(args)(undefined);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyOnYourOwn]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyOnYourOwn,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      switch (result) {
        case StudentLoanApplyOnYourOwnResult.ApplyWithUs:
          navigate(RoutePath.StudentLoanApplyWithUs);
          break;
        case StudentLoanApplyOnYourOwnResult.Done:
          next(args)(undefined);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyWithUs]: {
    navigationInfo: { showBackLink: true, title },
    component: BookAdvisor,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanApplyOptions);
    },
  },
  [RoutePath.StudentLoanApplySubmit]: {
    navigationInfo: { showBackLink: true, title },
    component: Submit,
    handleNext: (args) => (result) => {
      const { navigate } = args;
      if (result === PaymentPageResult.AlreadyPaid) {
        navigate(RoutePath.StudentLoanApplySteps);
      } else if (result === PaymentPageResult.PayrollDeduction) {
        navigate(RoutePath.StudentLoanApplyStepsIntro);
      } else if (result === PaymentPageResult.NotNow) {
        next(args)(undefined);
      } else {
        navigate(RoutePath.Error);
      }
    },
  },
  [RoutePath.StudentLoanApplyPaymentResult]: {
    navigationInfo: { showBackLink: true, title },
    component: PaymentResult,
    handleNext: ({ navigate }) => (result) => {
      if (result === StudentLoanPayingResult.Success) {
        navigate(RoutePath.StudentLoanApplyStepsIntro);
      } else {
        navigate(RoutePath.StudentLoanApplySubmit);
      }
    },
  },
  [RoutePath.StudentLoanApplyStepsIntro]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyStepsIntro,
    handleNext: ({ navigate }) => () => {
      navigate(RoutePath.StudentLoanApplySteps);
    },
  },

  [RoutePath.StudentLoanApplySteps]: {
    component: ApplyNavigation,
    handleNext: ({ navigate }) => async (result) => {
      switch (result) {
        case StepsResult.Completed:
          navigate(RoutePath.StudentLoanApplyStepsCompleted);
          break;
        default:
          navigate(RoutePath.Error);
          break;
      }
    },
  },
  [RoutePath.StudentLoanApplyStepsCompleted]: {
    navigationInfo: { showBackLink: true, title },
    component: ApplyStepsCompleted,
    handleNext: next,
  },
  [RoutePath.StudentLoanServiceAgreementDone]: {
    navigationInfo: { showBackLink: false },
    component: ServiceAgreementDone,
    handleNext: ({ navigate }) => (result) => {
      switch (result) {
        case StepsResult.Completed:
          navigate(RoutePath.StudentLoanApplyStepsCompleted);
          break;
        default:
          navigate(RoutePath.StudentLoanApplySteps);
          break;
      }
    },
  },
});

export const getStudentLoanStart = (studentLoanData: StudentLoanDataState) => {
  switch (studentLoanData.knownEmployerProfitStatus) {
    case KnownEmployerProfitStatus.NonProfit:
      return RoutePath.StudentLoanNonProfitStartDate;
    case KnownEmployerProfitStatus.ForProfit:
      return RoutePath.StudentLoanDependentsQuantity;
    default:
      return RoutePath.StudentLoanEmployerType;
  }
};

export const getStudentLoanSavings = (state: RootState) => {
  const { studentLoanData } = state;
  const currentTotal = studentLoanData?.currentPath?.totalPayment;
  const recommendedTotal = studentLoanData?.recommendedPath?.totalPayment;
  return currentTotal !== undefined && recommendedTotal !== undefined ? currentTotal - recommendedTotal : 0;
};

export const shouldShowStudentLoanSavings = (state: RootState) => getStudentLoanSavings(state) > 0;
