import React, { useEffect, useState } from 'react';
import { ReactComponent as MagnifyingGlassLoading } from 'images/magnifying-glass.svg';
import { ReactComponent as BankLoading } from 'images/bank-loader.svg';
import { RoutePath } from 'enums/Routes';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getApplicationApr, getApplicationData, getLoanOffer } from 'thunks';
import { LoanOfferResponse } from 'handlers/loanOffer';
import { ConversionType, trackConversion, trackConversionLead } from 'utils/analytics';
import { useNavigate, useNavigationType } from 'hooks/useNavigate';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import { useQueryParams } from 'hooks/useQueryParam';
import StateContainer from 'components/StateContainer';
import { ErrorType } from 'components/Error/ErrorType';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { FlowComponentType } from 'routes/types';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { LoanOfferProgress, LoanOfferStep } from 'api/LoanOfferApi';
import { getAuthData } from 'selectors/getAuthData';
import { getYourIncome } from 'selectors/yourIncome';
import { getProfessionGroup } from 'selectors/professionGroup';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getClientTimezone } from 'utils/dateUtils';
import { setStudentLoanApplicationId } from 'handlers/studentLoanData';
import { ApplyingResult as Result } from 'enums/FlowNextResults';
import { getYourBirthDateData } from 'selectors/yourBirthDate';
import RequestError from 'errors/RequestError';
import { getApplicationStep } from 'selectors/getApplicationStep';

import styles from './Analyzing.module.scss';

export const getCheckupProgress = (offerProgress: LoanOfferProgress) => {
  let progress;
  switch (offerProgress.step) {
    case LoanOfferStep.ValidatedInput:
      progress = 20;
      break;
    case LoanOfferStep.GettingTradelines:
      progress = 20;
      break;
    case LoanOfferStep.SyncingTradelines:
      if (offerProgress.initialAccounts && offerProgress.syncingAccounts !== undefined) {
        progress = 80 - (50 * offerProgress.syncingAccounts) / offerProgress.initialAccounts;
      } else {
        progress = 20;
      }
      break;
    case LoanOfferStep.RunningDecisionEngine:
      progress = 75;
      break;
    case LoanOfferStep.GeneratingDebtProfile:
      progress = 80;
      break;
    case LoanOfferStep.CreatingHardOffer:
      progress = 90;
      break;
    default:
      progress = 0;
  }
  return progress;
};

export const getProgressText = (progress: LoanOfferProgress, accountSync: boolean, totalAccounts: number) => {
  let title = "We're analyzing your finances...";
  if (accountSync) {
    if (progress.initialAccounts !== progress.syncingAccounts && progress.syncingAccounts) {
      title = `Synced ${progress.syncingAccounts}/${progress.initialAccounts} accounts`;
    } else if (totalAccounts) {
      title = `We've found ${totalAccounts} accounts`;
    }
  }
  return title;
};

const Analyzing = ({ handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const navigationType = useNavigationType();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const dispatch = useDispatch();

  const [progress, setProgress] = useState(0);
  const [accountSync, setAccountSync] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [lastProgress, setLastProgress] = useState<LoanOfferProgress | undefined>(undefined);

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const {
    not_employed: notEmployed,
    total_annual_income: totalAnnualIncome,
    start_of_employment: startOfEmployment,
    employer_name: employerName,
    job_title: jobTitle,
    pay_frequency: payFrequency,
  } = useSelector(getYourIncome);
  const { professionGroup } = useSelector(getProfessionGroup);
  const { entityId, cannotRetrieveData, failed: methodAuthFailed, requiresReAuthorization } = useSelector(
    getMethodAuthData,
  );
  const { partnerName } = useSelector(getPreQualificationData);
  const { sessionToken } = useSelector(getAuthData);
  const { birth_date: birthDate } = useSelector(getYourBirthDateData);
  const { currentFlow } = useSelector(getApplicationStep);

  useEffect(() => {
    const generateOffer = async () => {
      if (methodAuthFailed) {
        if (requiresReAuthorization) {
          handleNext(Result.SessionExpired);
          return;
        }
        if (cannotRetrieveData && !birthDate) {
          handleNext(Result.CannotRetrieveMethodData);
          return;
        }
        if (!entityId) {
          handleNext(Result.Error);
        }
      }

      if (!entityId) {
        return;
      }

      let loanOfferResponse: LoanOfferResponse;

      setProgress(10);

      try {
        loanOfferResponse = await dispatchWithUnwrap(
          getLoanOffer({
            application: {
              firstName,
              lastName,
              professionGroup,
              email,
              phoneNumber,
              entityId,
              totalAnnualIncome: totalAnnualIncome ?? 0,
              resumeLink: `${window.location.href}`,
              partner: partnerName,
            },
            notEmployed,
            employment: [
              {
                employerName,
                jobTitle,
                hireDate: startOfEmployment ?? undefined,
                payFrequency,
              },
            ],
            timezone: getClientTimezone(),
            currentFlow,
            utm: getUtmTagsVariables(params),
            sessionToken,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updateCallback: (data) => {
              setProgress(getCheckupProgress(data));
              if (
                [LoanOfferStep.GettingTradelines, LoanOfferStep.SyncingTradelines].includes(data.step) &&
                data.totalAccounts
              ) {
                setAccountSync(true);
                setTotalAccounts(data.totalAccounts || 0);
              }
              setLastProgress(data);
            },
          }),
        );
        dispatch(setStudentLoanApplicationId(loanOfferResponse.data.application_id!));
      } catch (error) {
        if ((error as RequestError<unknown>).responseStatus === 401) {
          handleNext(Result.SessionExpired);
        } else {
          navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
        }
        return;
      }

      setProgress(100);

      // only send to offer status if already applied
      if (loanOfferResponse.data.application_status === ApplicationStatusName.AlreadyApplied) {
        navigate(RoutePath.OfferStatus);
        return;
      }

      if (loanOfferResponse.data.application_status === ApplicationStatusName.OfferAvailable) {
        // getting the apr from the LMS takes up to 3 seconds, so we pre-fetch it after the loan offer is generated
        dispatch(getApplicationApr(loanOfferResponse.data.application_id!));
      }

      if (loanOfferResponse.data.borrower_id) {
        analytics.identify(loanOfferResponse.data.borrower_id);
        (window as any).nid('setUserId', loanOfferResponse.data.application_id);
      }

      const isQualified = loanOfferResponse.data.credit_passed;

      if (loanOfferResponse.data.application_id) {
        await dispatchWithUnwrap(getApplicationData(loanOfferResponse.data.application_id!));
      }
      if (isQualified) {
        trackConversionLead({
          email,
          firstName,
          lastName,
          phoneNumber,
        });
      }
      trackConversion(ConversionType.FinancialCheckupApplied);

      handleNext(Result.Done);
    };

    if (navigationType === 'POP') {
      navigate(RoutePath.YourFinances);
      return;
    }
    generateOffer();
  }, [entityId]);

  return (
    <div className={styles.container}>
      <StateContainer
        icon={accountSync ? <BankLoading /> : <MagnifyingGlassLoading />}
        progress={progress}
        title={getProgressText(lastProgress!, accountSync, totalAccounts)}
      />
    </div>
  );
};

export default Analyzing;
