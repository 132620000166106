import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PayrollProviderName } from 'api/EmployersApi';
import { ArgyleLinkDataResponse } from 'api/ArgyleApi';

import { disablePayrollProvider } from 'handlers/payrollProviders';
import { GetApplicationData } from 'handlers/applicationData';

import { getArgyleLinkData, getPayDistributionConfigsEncrypt, saveArgyleAccount } from 'thunks';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';

import { useScript } from 'components/ScriptsLoader/ScriptsLoader';

interface ArgyleLoginProps {
  open?: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
  application: GetApplicationData;
  isDDS?: boolean;
}

const ArgyleWidget: FC<ArgyleLoginProps> = ({ open, onClose, onSuccess, application, isDDS }) => {
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const [ddsConfig, setDdsConfig] = useState<string | null>(null);
  const [argyleLinkData, setArgyleLinkData] = useState<ArgyleLinkDataResponse | null>(null);
  const argyleScript = useScript('https://plugin.argyle.com/argyle.web.v5.js', {
    onError: () => dispatch(disablePayrollProvider({ provider: PayrollProviderName.Argyle })),
  });

  useEffect(() => {
    if (isDDS) {
      (async () => {
        const { encryptedConfig } = await dispatchWithUnwrap(getPayDistributionConfigsEncrypt(application.id));
        setDdsConfig(encryptedConfig);
      })();
    }
    (async () => {
      const linkData = await dispatchWithUnwrap(getArgyleLinkData({ applicationId: application.id }));
      setArgyleLinkData(linkData);
    })();
  }, []);

  const linkItems = application.employment.employer1.linkItemId
    ? [application.employment.employer1.linkItemId.toLowerCase()]
    : [];

  const openArgyleLogin = () => {
    const accountId =
      argyleLinkData!.accounts && argyleLinkData!.accounts?.length > 0
        ? argyleLinkData!.accounts[argyleLinkData!.accounts.length - 1]
        : null;

    // @ts-ignore
    const argyleInstance = window.Argyle.create({
      sandbox: process.env.REACT_APP_ENVIRONMENT === 'staging',
      pluginKey: process.env.REACT_APP_ARGYLE_PLUGIN_KEY,
      flowId: process.env.REACT_APP_ARGYLE_CUSTOMIZATION_ID,
      linkItems,
      ...(isDDS && {
        payDistributionItemsOnly: true,
        payDistributionUpdateFlow: true,
        ddsConfig,
      }),
      userToken: argyleLinkData?.userToken,
      accountId,

      onAccountConnected: async (payload: any) => {
        await dispatch(saveArgyleAccount({ applicationId: application.id, accountId: payload.accountId }));
        if (!isDDS) {
          argyleInstance.close();
          onSuccess && onSuccess();
        }
      },
      onDDSSuccess: async () => {
        argyleInstance.close();
        onSuccess && onSuccess();
      },
      onClose,
      onUIEvent: (event: any) => {
        analytics.track(`Argyle: ${event.name}`, event.properties);
      },
      onTokenExpired: async (updateToken: (token: string) => void) => {
        const linkData = await dispatchWithUnwrap(
          getArgyleLinkData({ applicationId: application.id, refreshUserToken: true }),
        );
        setArgyleLinkData(linkData);
        updateToken(linkData?.userToken);
      },
    });

    argyleInstance.open();
  };

  useEffect(() => {
    if (open && (!isDDS || ddsConfig) && argyleLinkData && argyleScript.loaded) {
      openArgyleLogin();
    }
  }, [open, ddsConfig, argyleLinkData, argyleScript.loaded]);

  return null;
};

export default ArgyleWidget;
